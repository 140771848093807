import { Token } from '@pancakeswap/sdk'

const getTokenLogoURL = (token?: Token | any) => {
  if (token?.tokenInfo?.logoURI.indexOf('default.svg') === -1) {
    return `${process.env.NEXT_PUBLIC_URL_SERVICE}${token?.tokenInfo?.logoURI}`
  }

  return `/images/tokens/${token?.address}.png`
}

export default getTokenLogoURL
