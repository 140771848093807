import { Flex, Button, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from '@pancakeswap/localization'
import { useGasPriceManager } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { ChainId } from '@pancakeswap/sdk'

const GasSettings = () => {
  const { t } = useTranslation()
  // const { chainId } = useActiveWeb3React()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const { isMobile } = useMatchBreakpoints()

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center">
        <Button
          mr="9px"
          scale="sm"
          className={
            gasPrice === GAS_PRICE_GWEI.default
              ? isMobile
                ? 'group-setting-mobile group-btn-active'
                : 'group-setting group-btn-active'
              : isMobile
              ? 'group-setting-mobile group-btn'
              : 'group-setting group-btn'
          }
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
            // console.log(GAS_PRICE_GWEI.default, gasPrice)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'primary' : 'tertiary'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </Button>
        <Button
          mr="9px"
          scale="sm"
          className={
            gasPrice === GAS_PRICE_GWEI.fast
              ? isMobile
                ? 'group-setting-mobile group-btn-active'
                : 'group-setting group-btn-active'
              : isMobile
              ? 'group-setting-mobile group-btn'
              : 'group-setting group-btn'
          }
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
            // console.log(GAS_PRICE_GWEI.fast, gasPrice)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'primary' : 'tertiary'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </Button>
        <Button
          mt="6px"
          scale="sm"
          className={
            gasPrice === GAS_PRICE_GWEI.instant
              ? isMobile
                ? 'group-setting-mobile group-btn-active'
                : 'group-setting group-btn-active'
              : isMobile
              ? 'group-setting-mobile group-btn'
              : 'group-setting group-btn'
          }
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
            // console.log(GAS_PRICE_GWEI.instant, gasPrice)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'primary' : 'tertiary'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
