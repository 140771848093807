import styled from 'styled-components'
import {
  Text,
  Flex,
  Box,
  Heading,
  IconButton,
  ArrowBackIcon,
  NotificationDot,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
// import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'
import { SettingsMode } from '../Menu/GlobalSettings/types'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string | (() => void)
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px 18px 20px;
  width: 100%;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints()

  // console.log("backTo===", backTo)

  return (
    <AppHeaderContainer>
      <Flex alignItems="center" width="100%" style={{ gap: '16px' }}>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column">
            <Heading as="h2" mb="8px">
              {title}
            </Heading>
            <Flex alignItems="center" width={isMobile ? '80%' : '100%'}>
              <Text color="subTitle" fontSize={isMobile ? '14px' : '16px'}>
                {subtitle}
              </Text>
              {helper && <QuestionHelper text={helper} ml="4px" placement="top-start" />}
            </Flex>
          </Flex>

          {backTo &&
            (typeof backTo === 'string' ? (
              <Link passHref href={backTo}>
                <IconButton as="a" scale="sm">
                  <ArrowBackIcon width="22px" />
                </IconButton>
              </Link>
            ) : (
              <IconButton scale="sm" variant="text" onClick={backTo}>
                <ArrowBackIcon width="22px" />
              </IconButton>
            ))}
          {!noConfig && !backTo && (
            <Box style={{ position: 'relative', left: '-10px', top: isMobile ? '-10px' : '-4px' }}>
              <NotificationDot show={expertMode}>
                <GlobalSettings mode={SettingsMode.SWAP_LIQUIDITY} />
              </NotificationDot>
              {/* <Transactions /> */}
            </Box>
          )}
        </Flex>
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
