import { useTheme } from 'styled-components'
import { Flex, IconButton, SettingIcon, SettingLightIcon, useModal } from '@pancakeswap/uikit'
import SettingsModal from './SettingsModal'

type Props = {
  color?: string
  mr?: string
  mode?: string
  isLimit?: boolean
}

const GlobalSettings = ({ color, mr, mode, isLimit }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={mode} isLimit={isLimit} />)
  const { isDark } = useTheme()

  return (
    <Flex>
      <IconButton
        onClick={onPresentSettingsModal}
        variant="text"
        scale="sm"
        mr={mr}
        id={`open-settings-dialog-button-${mode}`}
      >
        {isDark ? <SettingIcon width={28} height={20} /> : <SettingLightIcon width={28} height={20} />}
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
